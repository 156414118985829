import { combineReducers, configureStore } from '@reduxjs/toolkit';

import mainSlice from './main-slice';
import authSlice from './authSlice';


const rootReducers = combineReducers({
    main: mainSlice,
    auth: authSlice
});

export const store = configureStore({
    reducer: rootReducers
});
