import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import { getUserMe, login } from "../../../api/api";
import { login as setLogin } from "../../../store/authSlice";
import { setLocalStorageAccessTokens } from "../../../utils/accessTokens";
import { useDispatch } from "react-redux";
import styles from "../Authorization.module.scss";

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const from = location.state?.from?.pathname || "/dashboard";

    const onSubmit = async (email, password) => {
        const { access, refresh } = await login(email, password);
        if (access) {
            setLocalStorageAccessTokens(access, refresh);
            const responseUserMe = await getUserMe(access);
            if (responseUserMe?.email) {
                dispatch(setLogin(responseUserMe));
                navigate(from, { replace: true });
            }
        }
    }

    return (
        <>
            <h2 className={styles.auth__title}>Sign in to your Dashboard</h2>
            <LoginForm onSubmit={onSubmit} />
            <div className={styles.bottomLink}>
                <span>Don't have an account yet?</span>
                <Link to="/sign-up" className={styles.bottomLink__link}>Sign Up</Link>
            </div>
        </>
    )
}
