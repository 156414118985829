import React, { useState } from "react";
// import CustomSelect from "../../Common/CustomSelect/CustomSelect";
// import flagUkraine from "../../../assets/icons/flag-ukraine.svg";
import styles from "../Authorization.module.scss";

const initialFormValues = {
    email: "",
    password: "",
    username: "",
    first_name: "",
    last_name: ""
};

// const countryCodes = [
//     { id: 1, value: "+380", label: "+380", img: flagUkraine },
//     { id: 2, value: "+971", label: "+971", img: flagUkraine },
//     { id: 3, value: "+432", label: "+432", img: flagUkraine }
// ]

export default function SignUpForm({ onSubmit }) {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    // const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0]);
    const [errors, setErrors] = useState({});
    const isPasswordsMatch = repeatPassword === formValues.password;
    const isFormComplete = formValues.email && !errors.email && formValues.password && !errors.password && isTermsAccepted && isPasswordsMatch;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    }

    const validateFormValues = () => {
        const newErrors = {};
        const minPassLength = 8;

        if (!formValues.email) {
            newErrors.email = "This field is required";
        } else {
            const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!emailRe.test(formValues.email.toLowerCase())) {
                newErrors.email = "Invalid email address";
            }
        }
        if (!formValues.password) {
            newErrors.password = "This field is required";
        } else {
            if (formValues.password.length < minPassLength) {
                newErrors.password = `Password must be at least ${minPassLength} characters`;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValidFormValues = validateFormValues();

        if (isValidFormValues) {
            onSubmit(formValues);
            setFormValues(initialFormValues);
            setRepeatPassword("");
            setIsTermsAccepted(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.form__row}>
                <div className={styles.form__group}>
                    <input
                        type="text"
                        name="first_name"
                        value={formValues.first_name}
                        placeholder="First Name"
                        className={styles.form__input}
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.form__group}>
                    <input
                        type="text"
                        name="last_name"
                        value={formValues.last_name}
                        placeholder="Last Name"
                        className={styles.form__input}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className={styles.form__group}>
                <input
                    type="text"
                    name="username"
                    value={formValues.username}
                    placeholder="Username"
                    className={`${styles.form__input} ${errors.username ? styles.error : ""}`}
                    onChange={handleChange}
                    onFocus={() => setErrors(prevErrors => ({ ...prevErrors, username: null }))}
                />
                {errors.username && <span className={styles.errorText}>{errors.username}</span>}
            </div>
            <div className={styles.form__group}>
                <input
                    type="text"
                    name="email"
                    value={formValues.email}
                    placeholder="Email*"
                    className={`${styles.form__input} ${errors.email ? styles.error : ""}`}
                    onChange={handleChange}
                    onFocus={() => setErrors(prevErrors => ({ ...prevErrors, email: null }))}
                />
                {errors.email && <span className={styles.errorText}>{errors.email}</span>}
            </div>
            <div className={styles.form__group}>
                <input
                    type="password"
                    name="password"
                    value={formValues.password}
                    placeholder="Password*"
                    className={`${styles.form__input} ${errors.password ? styles.error : ""}`}
                    onChange={handleChange}
                    onFocus={() => setErrors(prevErrors => ({ ...prevErrors, password: null }))}
                />
                {errors.password && <span className={styles.errorText}>{errors.password}</span>}
            </div>
            <div className={styles.form__group}>
                <input
                    type="password"
                    name="repeatPassword"
                    value={repeatPassword}
                    placeholder="Confirm password"
                    className={`${styles.form__input} ${repeatPassword && !isPasswordsMatch ? styles.error : ""}`}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                />
            </div>
            {/* <div className={`${styles.form__group} ${styles.form__groupPhone}`}>
                <div className={styles.phoneSelect}>
                    <CustomSelect options={countryCodes} selectedValue={selectedCountryCode} onChange={(code) => setSelectedCountryCode(code)} />
                </div>
                <input
                    type="tel"
                    name="phone"
                    value={formValues.phone}
                    placeholder="32 322 4632"
                    className={`${styles.form__input} ${styles.phoneInput}`}
                    onChange={handleChange}
                />
            </div> */}
            <div className={`${styles.form__group} ${styles.terms}`}>
                <label className={styles.terms__label}>
                    <input type="checkbox" checked={isTermsAccepted} onChange={() => setIsTermsAccepted(prev => !prev)} />
                    <span>I Agree with the terms and conditions</span>
                </label>
            </div>
            <button type="submit" disabled={!isFormComplete} className={styles.form__submitBtn}>Continue</button>
        </form>
    )
}
