import React from "react";
import styles from './AboutUs.module.scss';
import digitalImage from '../../../assets/images/digitalAbout.png';
import usersImage from '../../../assets/images/users.png';
import arrow from '../../../assets/icons/buttonArrow.svg';

const AboutUs = () => {
    const stats = [
        {value: '5+', text: 'Networks Supported'},
        {value: '20+', text: 'Specialists'},
        {value: '50+', text: 'Projects Implementations'},
        {value: '24/7', text: 'Client Support'},
        {value: '25 ms', text: 'Avg Processing Time'},
    ]

    return (
        <div className={styles.aboutUsWrapper} id="about-us">
            <div className={styles.aboutUs}>
                {window.innerWidth > 768 && (
                    <div className={styles.aboutUs__image}>
                        <img src={digitalImage} alt=""/>
                    </div>
                )}
                <div className={styles.aboutUs__info}>
                    <h3>About Us</h3>
                    <div className={styles.aboutUs__content}>
                        <div className={styles.aboutUs__users}>
                            <img src={usersImage} alt=""/>
                            <span>More than 20k users</span>
                        </div>
                        <p className={styles.aboutUs__text}>
                            The Y-API team is a community of experienced blockchain project
                            developers who know their customers' needs best. After many years of work, we came together in 2024
                            to create simple and efficient APIs for ease of use and money saving for our customers. We support
                            the development of web 3.0 and are ready to contribute to business or personal projects around the
                            world to popularize blockchain.
                        </p>
                    </div>
                    <div className={styles.aboutUs__action}>
                        <button className='colorBtn'>
                            <span>Follow us on Discord</span>
                            <img src={arrow} alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.stats}>
                {stats && (
                    stats.map((item, index) => (
                        <div key={index} className={styles.stats__item}>
                            <span>{item.value}</span>
                            <p>{item.text}</p>
                        </div>
                    ))
                )}
            </div>
        </div>

    )
}

export default AboutUs;