import { getLocalStorageAccessTokens, removeLocalStorageAccessTokens, setLocalStorageAccessToken } from "../utils/accessTokens";

const baseURL = 'https://api.y-api.com/'

export const setFeedback = async ({ name, email, phone, comment }) => {
    try {
        const response = await fetch(`${baseURL}api/v1/feedback/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                comment: comment
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const getBlogs = async () => {
    try {
        const response = await fetch(`${baseURL}blogs/`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const signUp = async ({ email, password, username, first_name, last_name }) => {
    try {
        const response = await fetch(`${baseURL}auth/users/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                username: username,
                first_name: first_name,
                last_name: last_name
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const login = async (email, password) => {
    try {
        const response = await fetch(`${baseURL}auth/jwt/create/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

const refreshAccessToken = async (refreshToken) => {
    try {
        const response = await fetch(`${baseURL}auth/jwt/refresh/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh: refreshToken
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

const fetchWithAuth = async (url, options = {}) => {
    const { accessToken, refreshToken } = getLocalStorageAccessTokens();

    if (!refreshToken) {
        return null;
    }
    const optionsWithAuth = {
        ...options,
        headers: {
            ...options.headers,
             'Authorization': `Bearer ${accessToken}`
        }
    };
    try {
        const response = await fetch(url, optionsWithAuth);

        if (!response?.ok) {
            const responseAccessToken = await refreshAccessToken(refreshToken);
            if (responseAccessToken?.access) {
                setLocalStorageAccessToken(responseAccessToken.access);
                const newOptions = {
                    ...options,
                    headers: {
                        ...options.headers,
                        'Authorization': `Bearer ${responseAccessToken.access}`,
                    },
                };
                const newResponse = await fetch(url, newOptions);
                return newResponse.json();
            } else {
                removeLocalStorageAccessTokens();
            }
        }

        return response.json();
    } catch (error) {
        console.error('Fetch error:', error);
    }
};

export const getUserMe = async () => {
    return fetchWithAuth(`${baseURL}auth/users/me/`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
}