import React, {useState} from "react";
import styles from './AboutHeader.module.scss';
import logo from '../../../assets/images/newLogo.svg'
import logoDark from '../../../assets/images/newLogoDark.svg'
import AboutNavigation from "./AboutNavigation";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsAuth } from "../../../store/selectors";

const AboutHeader = ({theme = 'dark'}) => {
    const [showNavigation, setShowNavigation] = useState(false);
    const isAuth = useSelector(getIsAuth);

    return (
        <div className={theme === 'dark' ? styles.aboutHeader : styles.aboutHeaderLight}>
            <Link to={'/'}><img src={theme === 'dark' ? logo : logoDark} alt=""/></Link>
            <AboutNavigation theme={theme} showNavigation={showNavigation}/>
            <div className={styles.mobileNav}>
                <div className={theme === 'dark' ? styles.auth : styles.authLight}>
                    {isAuth ? (
                        <Link to="/dashboard" className={theme === 'dark' ? styles.auth__login : styles.authLight__login}>Dashboard</Link>
                    ) : (
                        <>
                            {window.innerWidth > 768 && (
                                <Link to="/login" className={theme === 'dark' ? styles.auth__login : styles.authLight__login}>Login</Link>
                            )}
                            <Link to="/sign-up" className={`${styles.auth__register} colorBtn`}>Sign Up</Link>
                        </>
                    )}
                </div>
                {window.innerWidth < 768 && (
                    <button className={showNavigation ? styles.burgerClose : styles.burger} onClick={() => setShowNavigation(!showNavigation)}>
                        <span className={theme === 'dark' ? '' : styles.burgerLight} style={showNavigation ? {backgroundColor: 'transparent'} : null} />
                    </button>
                )}
            </div>
        </div>
    )
}

export default AboutHeader;