import React from "react";
import styles from './GetStarted.module.scss';
import image from '../../../assets/images/calculating.png';
import {Link} from "react-router-dom";

const GetStarted = () => {
    return (
        <div className={styles.getStarted}>
            <p className={styles.getStarted__mainText}>Spend less on development, get more profit</p>
            <div className={styles.getStarted__image}>
                <img src={image} alt=""/>
            </div>
            <p className={styles.getStarted__secondText}>Welcome to Y-API, which helps in the development of crypto and blockchain projects of any complexity</p>
            <div className={styles.getStarted__actions}>
                <Link to={'/getStart/'} className={`${styles.getStarted__getStartedBtn} colorBtn`} ><span>Get started for free</span></Link>
                <a
                    className={styles.getStarted__view}
                    href="https://api.y-api.com/api/v1/docs/"
                    target="_blank" rel="noreferrer"
                >View Docs</a>
            </div>
        </div>
    )
}

export default GetStarted;