import React, {useState} from "react";
import styles from './StartForm.module.scss';
import { setFeedback } from "../../api/api";
import { createPortal } from "react-dom";
import ConfirmationPopup from "../Common/ConfirmationPopup/ConfirmationPopup";

const initialFormValues = {
    name: "",
    email: "",
    phone: "",
}

const FormFields = () => {
    const [requiredFormValues, setRequiredFormValues] = useState(initialFormValues);
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [confirmationData, setConfirmationData] = useState(null);

    const handleChange = (name, value) => {
        if (name === "phone" && !(/^(\+)?[0-9]*$/.test(value))) return
        setRequiredFormValues(prevValues => ({ ...prevValues, [name]: value }));
    }

    const validateFormValues = () => {
        const newErrors = {};

        for (const [key, value] of Object.entries(requiredFormValues)) {
            if (!value) {
                newErrors[key] = "This field is required";
            }
        }

        if (requiredFormValues.email) {
            const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!emailRe.test(requiredFormValues.email.toLowerCase())) {
                newErrors.email = "Invalid email address";
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
            return false;
        }

        return true;
    }

    const handleConfirmPopup = () => {
        setRequiredFormValues(initialFormValues);
        setComment("");
        setConfirmationData(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValidFormValues = validateFormValues();

        if (isValidFormValues) {
            setIsFetching(true);
            const values = comment ? { ...requiredFormValues, comment } : { ...requiredFormValues };
            setFeedback(values).then(data => {
                if (data.name) {
                    setConfirmationData({ success: true, text: "Success" });
                } else {
                    setConfirmationData({ success: false, text: "Something went wrong ;(" });
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => setIsFetching(false))
        }
    }

    return (
        <form onSubmit={handleSubmit} className={styles.formFieldsWrapper}>
            <div className={styles.formFields}>
                <div className={styles.formGroup}>
                    <input
                        type="text" placeholder={'name*'}
                        className={`${styles.input} ${errors?.name ? styles.error : ''}`}
                        value={requiredFormValues.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        onFocus={() => setErrors(prevErrors => ({ ...prevErrors, name: null }))}
                    />
                    {errors.name && <span className={styles.errorText}>{errors.name}</span>}
                </div>
                <div className={styles.formGroup}>
                    <input
                        type="text"
                        placeholder={'e-mail*'}
                        className={`${styles.input} ${errors?.email ? styles.error : ''}`}
                        value={requiredFormValues.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        onFocus={() => setErrors(prevErrors => ({ ...prevErrors, email: null }))}
                    />
                    {errors.email && <span className={styles.errorText}>{errors.email}</span>}
                </div>
                <div className={styles.formGroup}>
                    <input
                        type="tel"
                        placeholder={'phone*'}
                        className={`${styles.input} ${errors?.phone ? styles.error : ''}`}
                        value={requiredFormValues.phone}
                        onChange={(e) => handleChange('phone', e.target.value)}
                        onFocus={() => setErrors(prevErrors => ({ ...prevErrors, phone: null }))}
                    />
                    {errors.phone && <span className={styles.errorText}>{errors.phone}</span>}
                </div>
                <div className={styles.formGroup}>
                    <textarea
                        placeholder={'Comment'}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.submit}>
                <button type="submit" className={'colorBtn'} disabled={isFetching}>
                    <span>Submit</span>
                </button>
                <span className={styles.submit__text}>By clicking the button, you consent to the processing of personal data and agree to the privacy policy</span>
            </div>
            {confirmationData && createPortal(
                <ConfirmationPopup success={confirmationData.success} confirmationText={confirmationData.text} onClick={handleConfirmPopup} />,
                document.body
            )}
        </form>

    )
}

export default FormFields;