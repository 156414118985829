import './Base.scss';
import React, { useEffect } from "react";
import MainAbout from "./components/MainAbout/MainAbout";
import {Routes, Route} from "react-router-dom"
import UseCasesPage from "./components/UseCasesPage/UseCasesPage";
import Blog from "./components/Blog/Blog";
import Pricing from "./components/Pricing/Pricing";
import BlogView from "./components/BlogView/BlogView";
import StartForm from "./components/StartForm/StartForm";
import SignUp from './components/Authorization/SignUp/SignUp';
import DashboardLayout from './layouts/DashboardLayout';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Authorization from './components/Authorization/Authorization';
import Login from './components/Authorization/Login/Login';
import { useDispatch } from 'react-redux';
import { login } from "./store/authSlice";
import { useUserAuth } from './hooks/useUserAuth';

function App() {
  const { userData } = useUserAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(login(userData));
    }
  }, [userData])

  return (
    <div>
      <Routes>
        <Route index element={<MainAbout />} />
        <Route path={'/useCasesPage/*'} element={<UseCasesPage />} />
        <Route path={'/blogs/*'} element={<Blog />} />
        <Route path={'/blogs/:id'} element={<BlogView/>}/>
        <Route path={'/pricing/*'} element={<Pricing />} />
        <Route path={'/getStart/*'} element={<StartForm />} />
        <Route path={'/sign-up/*'} element={<Authorization><SignUp /></Authorization>} />
        <Route path={'/login/*'} element={<Authorization><Login /></Authorization>} />

        <Route path={'/dashboard/*'} element={<RequireAuth><DashboardLayout /></RequireAuth>}>
          <Route index element={<div>Dashboard home page</div>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
