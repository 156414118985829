import React from "react";
import styles from './UseCases.module.scss';
import img1 from '../../../assets/images/caseImg1.png';
import img2 from '../../../assets/images/caseImg2.png';
import background1 from '../../../assets/backgrounds/gradientUseCase1.svg';
import background2 from '../../../assets/backgrounds/gradientUseCase2.svg';
import background3 from '../../../assets/backgrounds/gradientUseCase3.svg';
import background4 from '../../../assets/backgrounds/gradientUseCase4.svg';
import background5 from '../../../assets/backgrounds/gradientUseCase5.svg';
import background6 from '../../../assets/backgrounds/gradientUseCase6.svg';
import background1Mob from '../../../assets/backgrounds/gradientUseCase1Mob.svg';
import background2Mob from '../../../assets/backgrounds/gradientUseCase2Mob.svg';
import background3Mob from '../../../assets/backgrounds/gradientUseCase3Mob.svg';
import background4Mob from '../../../assets/backgrounds/gradientUseCase4Mob.svg';
import background5Mob from '../../../assets/backgrounds/gradientUseCase5Mob.svg';
import background6Mob from '../../../assets/backgrounds/gradientUseCase6Mob.svg';

const UseCases = () => {
    const cases = [
        {
            title: 'Use Cases',
            text: 'Learn about effective examples of the use of our APIs in the development of various blockchain projects and compare with your business needs.',
            image: img1,
            background: background1,
            backgroundMob: background1Mob,
        },
        {
            title: 'Personal microservices',
            text: 'Find out in which cases Y-API  will be an indispensable solution for you.',
            image: null,
            background: background2,
            backgroundMob: background2Mob,
        },
        {
            title: 'Library of ready-made APIs',
            text: 'Combine ready-made solutions to save time and budgets.',
            image: null,
            background: background3,
            backgroundMob: background3Mob,
        },
        {
            title: 'Increasing the level of security',
            text: 'Protect your project with reliable technologies',
            image: null,
            background: background4,
            backgroundMob: background4Mob,
        },
        {
            title: 'Always ready to help',
            text: 'We will help you avoid most common problems',
            image: null,
            background: background5,
            backgroundMob: background5Mob,
        },
        {
            title: 'Scale your business',
            text: 'Attract investors or partners and customize your APIs for new goals and tasks',
            image: img2,
            background: background6,
            backgroundMob: background6Mob,
        }

    ]
    return (
        <>
            {window.innerWidth < 768 && (
                <h3 className={styles.title}>Use Cases </h3>
            )}
            <div className={styles.useCases} id="use-cases">
                {cases && (
                    cases.map((item, index) => (
                        <div key={index} className={styles.useCases__item}
                             style={window.innerWidth > 767 ? {backgroundImage: `url(${item.background})`} : {backgroundImage: `url(${item.backgroundMob})`}}>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                            {item.image && (
                                <img src={item.image} alt=""/>
                            )}
                        </div>
                    ))
                )}
            </div>
        </>

    )
}

export default UseCases;