const ACCESS_TOKEN = "accessToken";
const REFRESH_TOKEN = "refreshToken";

export const getLocalStorageAccessTokens = () => {
    return {
        accessToken: localStorage.getItem(ACCESS_TOKEN),
        refreshToken: localStorage.getItem(REFRESH_TOKEN)
    }
}

export const setLocalStorageAccessTokens = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export const setLocalStorageAccessToken = (accessToken) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export const removeLocalStorageAccessTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
}