import { useState } from "react";
import styles from "../Authorization.module.scss";

const initialFormValues = {
    email: "",
    password: ""
};

export default function LoginForm({ onSubmit }) {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [errors, setErrors] = useState({});
    const isFormComplete = formValues.email && formValues.password;

    const validateFormValues = () => {
        const newErrors = {};

        if (!formValues.email) {
            newErrors.email = "This field is required";
        }

        if (!formValues.password) {
            newErrors.password = "This field is required";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
            return false;
        }

        return true;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValidFormValues = validateFormValues();

        if (isValidFormValues) {
            onSubmit(formValues.email, formValues.password);
            setFormValues(initialFormValues);
            setIsRememberMe(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.form__group}>
                <input
                    type="email"
                    name="email"
                    value={formValues.email}
                    placeholder="Email"
                    className={`${styles.form__input} ${errors.email ? styles.error : ""}`}
                    onChange={handleChange}
                    onFocus={() => setErrors(prevErrors => ({ ...prevErrors, email: null }))}
                />
                {errors.email && <span className={styles.errorText}>{errors.email}</span>}
            </div>
            <div className={styles.form__group}>
                <input
                    type="password"
                    name="password"
                    value={formValues.password}
                    placeholder="Password"
                    className={`${styles.form__input} ${errors.password ? styles.error : ""}`}
                    onChange={handleChange}
                    onFocus={() => setErrors(prevErrors => ({ ...prevErrors, password: null }))}
                />
                {errors.password && <span className={styles.errorText}>{errors.password}</span>}
            </div>
            <div className={styles.form__row}>
                <div className={`${styles.form__group} ${styles.rememberMe}`}>
                    <label className={styles.rememberMe__label}>
                        <input type="checkbox" checked={isRememberMe} onChange={() => setIsRememberMe(prev => !prev)} />
                        <span>Remember me</span>
                    </label>
                </div>
                <div className={`${styles.form__group} ${styles.form__forgotPassLink}`}>
                    <a href="#" className={styles.link}>Forgot your password?</a>
                </div>
            </div>
            <button type="submit" disabled={!isFormComplete} className={styles.form__submitBtn}>Sign In</button>
        </form>
    )
}
