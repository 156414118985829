import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { getIsAuth } from "../../store/selectors";
import { Link } from "react-router-dom";
import logoDark from "../../assets/images/newLogoDark.svg";
import styles from "./Authorization.module.scss";

export default function Authorization({ children }) {
    const isAuth = useSelector(getIsAuth);

    if (isAuth) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <div className={styles.auth}>
            <div className={styles.auth__content}>
                <Link to="/" className={styles.logo}>
                    <img src={logoDark} alt="Logo dark" />
                </Link>
                {children}
            </div>
        </div>
    )
}
