import styles from "./ConfirmationPopup.module.scss";
import checkMark from "../../../assets/icons/check-mark-circle.svg";
import crossMark from "../../../assets/icons/circle-cross.svg";

export default function ConfirmationPopup({ success, confirmationText, onClick }) {
    return (
        <div className={styles.outer}>
            <div className={styles.popup}>
                <div className={styles.popup__img}>
                    {success ? (
                        <img src={checkMark} alt="Check mark" />
                    ) : (
                        <img src={crossMark} alt="Cross mark" />
                    )}
                </div>                
                <p className={styles.popup__confirmationText}>{confirmationText}</p>
                <button onClick={onClick} className={`${styles.popup__submit} colorBtn`}>Confirm</button>
            </div>
        </div>
    )
}
