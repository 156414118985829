import { useEffect, useState } from "react";
import { getUserMe } from "../api/api";

export const useUserAuth = () => {
    const [userData, setUserData] = useState(null);

    const handleLogin = async () => {
        const response = await getUserMe();
        if (response?.email) {
            setUserData(response);
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        handleLogin();
    }, [])

    return { userData };
}