import SignUpForm from "./SignUpForm";
import { Link, useNavigate } from "react-router-dom";
import { login, signUp } from "../../../api/api";
import { useDispatch } from "react-redux";
import { login as setLogin } from "../../../store/authSlice";
import { setLocalStorageAccessTokens } from "../../../utils/accessTokens";
import styles from "../Authorization.module.scss";

export default function SignUp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (formValues) => {
        const responseSignUp = await signUp(formValues);
        if (responseSignUp?.email) {
            const { access, refresh } = await login(responseSignUp.email, formValues.password);
            if (access) {
                dispatch(setLogin(responseSignUp));
                setLocalStorageAccessTokens(access, refresh);
                navigate("/dashboard");
            } else {
                navigate("/login");
            }
        }
    }

    return (
        <>
            <h2 className={styles.auth__title}>Sign Up</h2>
            <SignUpForm onSubmit={onSubmit} />
            <div className={styles.bottomLink}>
                <span>Already have an account?</span>
                <Link to="/login" className={styles.bottomLink__link}>Sign In</Link>
            </div>
        </>
    )
}
