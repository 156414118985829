import { useSelector } from "react-redux";
import { getIsAuth } from "../../store/selectors";
import { Navigate, useLocation } from "react-router";

export default function RequireAuth({ children }) {
    const isAuth = useSelector(getIsAuth);
    const location = useLocation();

    return isAuth ? children : <Navigate to="/login" state={{ from: location }} replace />;
}
